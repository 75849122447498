import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useHistory, useLocation } from "react-router-dom";
import {
  amplitudeBtnEvent,
  amplitudeCustomEvent,
  ampRabbitMQBtnEvent,
  ampRabbitMQCustomEvent,
  ampRabbitMQsubBtnClickPageEvent
} from "../../../utils";
import { Image } from "../../subcomponents/Elements";
import { verifyCouponCode, Capitalize } from "../../../utils/services";
import { SUCCESS } from "../../../../constants";
import { langConvrt } from "../../../utils";

export default function Plan({
  planDetailsId,
  plansData,
  plansDataCopy,
  setPlansData,
  updateMultipleFormData,
  applyCouponCode,
  identity,
  product_id,
  platform_id,
  setSeeBenefit,
  setSelectedPlan,
  setSeeSubcribe,
  selectedPlan
}) {
  const [applyBtn, setApplyBtn] = useState(false);
  const [showApplyBtn, setShowApplyBtn] = useState(true);

  let history = useHistory();
  const location = useLocation();
  let currenturl = useLocation().search;
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  /* react ga code in useEffect */
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(location.pathname);
  }, [TRACKING_ID, location]);


  const redirectTC = () =>{
    window.open(process.env.REACT_APP_TERMS_PAGE, "_blank");
  }

  const fieldData = plansData.formData;
  let planData = plansData.data;

  if (planData) {
    let isAfterMatch = false;

    planData = {
      ...planData,
      plans: planData.plans.map(plan => {
        if (plan.plan_details_id == planDetailsId || isAfterMatch) {
          isAfterMatch = true;
          return {
            ...plan,
            isShow: false
          };
        }
        return {
          ...plan,
          isShow: true
        };
      })
    };
  }

  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const handleClearCoupon = () => {
    //couponcode cancel logic
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon");
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_Remove_Coupon"
    );

    updateMultipleFormData({ couponCode: "" });
    setShowApplyBtn(true);
    setApplyBtn(false);

    //set original plans
    setPlansData({ type: "plansData", payload: plansDataCopy });
  };
  const handleCouponOnChange = (coupon) => {
    if (coupon !== "") {
      setApplyBtn(true);
      updateMultipleFormData({ couponCode: coupon });
    } else if (coupon === "") {
      updateMultipleFormData({ couponCode: coupon });
      setApplyBtn(false);
    }
  };
  const verifyCoupon = (event) => {
    event.preventDefault();
    if (fieldData.couponCode !== "") {
      // amplitudeBtnEvent(fieldData.identity, "apply_coupon");
      ampRabbitMQBtnEvent(
        {
          identity: fieldData.identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "apply_coupon"
      );
      event.preventDefault(); // Prevent default submission
      setShowApplyBtn(false);
      gaEventTracker("apply_coupon", "HUNGAMA_" + fieldData.couponCode);
      verifyCouponCode(fieldData.couponCode).then((res) => {
        if (res.status === SUCCESS) {
          applyCouponCode(res?.data);
          if (
            res.data?.status === 2 &&
            res.data?.coupon_details?.type === "free"
          ) {
            // response.is_coupon_valid = true;
            // Patch :: Assign Half_yearly Plan via coupon code and go directly to resp success/fail page
            let obj = {
              couponCode: res.data?.coupon_code,
              status: res.data?.status,
              ...res.data?.coupon_details,
            };
            let { couponCode, payment_id, plan_details_id, plan_id } = obj;

            return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL
              }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${plan_id}&coupon_code=${couponCode}&${currenturl?.replace(
                "?",
                ""
              )}`);
          }
        } else {
          // updateMultipleFormData({ validStatus: true });
        }
      });
    }
  };



  const gaEventTracker = useAnalyticsEventTracker("Payment Plan");
  const callupdate = (a) => {
    /*
    amplitudeCustomEvent(fieldData.identity, "npay_click_planselect", {
      npay_currency: a.plan_currency,
      npay_planid: a.plan_id,
      npay_planname: a.plan_name,
      npay_plan_validity:
        a?.plan_duration?.duration + " " + a.plan_duration?.text,
      npay_planvalue: a.plan_price,
    });
    */

    ampRabbitMQCustomEvent(
      {
        identity: fieldData.identity,
        npay_currency: a.plan_currency,
        npay_planid: a.plan_id,
        npay_planname: a.plan_name,
        npay_plan_validity:
          a?.plan_duration?.duration + " " + a.plan_duration?.text,
        npay_planvalue: a.plan_price,
        product_id: product_id,
        platform_id: platform_id,
      },
      "npay_click_planselect"
    );

    document.getElementById("x_plan_name").value = a.plan_name;
    document.getElementById("x_plan_price").value = a.plan_price;
    gaEventTracker("pick_plan", a.plan_name);
  };
  const buyGold = (event) => {
    event.preventDefault(); // Prevent default submission
    // amplitudeBtnEvent(fieldData.identity, "Btn_BottomFix_Buy Now");
    ampRabbitMQsubBtnClickPageEvent(
      {
        identity: fieldData.identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_BottomFix_Buy Now"
    );

    var planId = [fieldData.plan_id];
    var filteredArray = plansData.data["plans"].filter(function (itm) {
      return planId.indexOf(itm.plan_id) > -1;
    });
    gaEventTracker(
      "buy_hungama_gold",
      filteredArray[0].plan_name + "_" + filteredArray[0].plan_price
    );
    if (fieldData.plan_id !== "") {
      if (fieldData.plan_id === 37) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("plan_type", "event");
        currenturl = searchParams.toString();
      }

      //Free coupon
      if (
        fieldData?.freeCouponData?.status === 1 &&
        fieldData?.freeCouponData?.coupon_details?.type === "free"
      ) {
        let obj = {
          couponCode: fieldData?.freeCouponData?.coupon_code,
          status: fieldData?.freeCouponData?.status,
          ...fieldData?.freeCouponData?.coupon_details,
        };
        let { couponCode, payment_id, plan_details_id, plan_id } = obj;
        return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL
          }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${plan_id}&coupon_code=${couponCode}&${currenturl.replace(
            "?",
            ""
          )}`);
      }

      history.push(
        `/payment?plan_id=${fieldData.plan_id}&couponcode=${fieldData.couponCode
        }&${currenturl.replace("?", "")}`
      );
    } else {
      alert("please select a plan");
    }
  };

  useEffect(() => {
    if (plansData && plansData?.plans) {
      setSelectedPlan(plansData?.plans[0]);
    }
  }, [plansData])

  return (
    <>
      <div className="planrightsec">
        <div className="selplantxt" style={{ margin: "20px" }}>{plansData?.language?.plan_heading_text}</div>
        <div className="clearfix planht">
          {planData?.plans?.map((x, i) => {
            // return (
            //   <div
            //     className={`bxplan ${x?.isShow === false ? "in-active-plan" : ""}`}
            //     key={i}
            //     onClick={() => {
            //       setSelectedPlan(x);
            //       setSeeBenefit(true);
            //     }}
            //   >
            //     {/* Free Trial */}
            //     {x.is_recommended && (
            //       <>
            //         <div className="rcomm" style={{ display: "none" }}>
            //           {x?.plan_id === 10
            //             ? "Loot Deal"
            //             : plansData?.language?.pay_recommended_text}
            //           {/* {plansData?.language?.pay_recommended_text} */}
            //         </div>
            //         <div>
            //           <img
            //             className="gifimg"
            //             alt="pay-gif"
            //             src="images/mostPopular.png"
            //           />
            //         </div>
            //       </>
            //     )}
            //     {x.freeTrialPlanRecText && (
            //       <div className="rcomm">
            //         {plansData?.language?.pay_trialoffer_text}
            //       </div>
            //     )}
            //     <div className="paddpan">
            //       <p className="typeplan">
            //         {langConvrt(
            //           x.plan_name,
            //           plansData?.defaultlang,
            //           plansData?.language
            //         )}
            //       </p>

            //       {x.discountedPrice ? (
            //         <div className="ruppetxt">
            //           <span>{`${x.plan_currency_symbol} ${x.plan_price}`}</span>
            //           {`${x.plan_currency_symbol} ${x.discountedPrice}`}
            //           <span className="plnval">
            //             {langConvrt(
            //               Capitalize(x.plan_name),
            //               plansData?.defaultlang,
            //               plansData?.language
            //             )}
            //           </span>
            //         </div>
            //       ) : //PRICE PLAN CHANGE
            //         x?.original_price > x?.plan_price ? (
            //           <div className="ruppetxt">
            //             <span>{`${x.plan_currency_symbol} ${x.original_price}`}</span>{" "}
            //             {`${x.plan_currency_symbol} ${x.plan_price}`}
            //             <span className="plnval">
            //               {langConvrt(
            //                 Capitalize(x.plan_name),
            //                 plansData?.defaultlang,
            //                 plansData?.language
            //               )}
            //             </span>
            //           </div>
            //         ) : (
            //           <div className="ruppetxt">
            //             {`${x.plan_currency_symbol} ${x.plan_price}`}
            //             <span className="plnval">
            //               {langConvrt(
            //                 Capitalize(x.plan_name),
            //                 plansData?.defaultlang,
            //                 plansData?.language
            //               )}
            //             </span>
            //           </div>
            //         )}
            //       {/* <span className="seeBenefits" onClick={() => { setSeeBenefit(true) }}>See Benefits</span> */}
            //       <span className="seeBenefits" onClick={() => { setSeeBenefit(true) }}>
            //         {plansData?.language?.plan_see_benefits_button_text}
            //       </span>
            //     </div>
            //     <label className="containern">
            //       {x?.is_recommended ? (
            //         <input
            //           type="radio"
            //           name="radio"
            //           onClick={(e) => {
            //             updateMultipleFormData({
            //               plan_id: x.plan_id,
            //               plan_name: x.plan_name,
            //               plan_price: x.plan_price,
            //               plan_currency: x.plan_currency,
            //               plan_duration: x.duration,
            //             });
            //             callupdate({
            //               plan_id: x.plan_id,
            //               plan_name: x.plan_name,
            //               plan_price: x.plan_price,
            //               plan_currency: x.plan_currency,
            //               plan_duration: x.duration,
            //             });
            //           }}
            //           defaultChecked="checked"
            //         />
            //       ) : (
            //         <input
            //           type="radio"
            //           name="radio"
            //           onClick={(e) => {
            //             updateMultipleFormData({
            //               plan_id: x.plan_id,
            //               plan_name: x.plan_name,
            //               plan_price: x.plan_price,
            //               plan_currency: x.plan_currency,
            //               plan_duration: x.duration,
            //             });
            //             callupdate({
            //               plan_id: x.plan_id,
            //               plan_name: x.plan_name,
            //               plan_price: x.plan_price,
            //               plan_currency: x.plan_currency,
            //               plan_duration: x.duration,
            //             });
            //           }}
            //         />
            //       )}
            //       <span className="checkmark"></span>
            //     </label>
            //   </div>
            // );
            // return (
            //   <div
            //     className={`bxplan ${x?.isShow === false ? "in-active-plan" : ""}`}
            //     key={i}
            //     onClick={() => setSelectedPlan(x)} // Handles plan selection
            //   >
            //     {/* Free Trial */}
            //     {x.is_recommended && (
            //       <>
            //         <div className="rcomm" style={{ display: "none" }}>
            //           {x?.plan_id === 10
            //             ? "Loot Deal"
            //             : plansData?.language?.pay_recommended_text}
            //         </div>
            //         <div>
            //           <img
            //             className="gifimg"
            //             alt="pay-gif"
            //             src="images/mostPopular.png"
            //           />
            //         </div>
            //       </>
            //     )}
            //     {x.freeTrialPlanRecText && (
            //       <div className="rcomm">
            //         {plansData?.language?.pay_trialoffer_text}
            //       </div>
            //     )}
            //     <div className="paddpan">
            //       <p className="typeplan">
            //         {langConvrt(
            //           x.plan_name,
            //           plansData?.defaultlang,
            //           plansData?.language
            //         )}
            //       </p>
            
            //       {x.discountedPrice ? (
            //         <div className="ruppetxt">
            //           <span>{`${x.plan_currency_symbol} ${x.plan_price}`}</span>
            //           {`${x.plan_currency_symbol} ${x.discountedPrice}`}
            //           <span className="plnval">
            //             {langConvrt(
            //               Capitalize(x.plan_name),
            //               plansData?.defaultlang,
            //               plansData?.language
            //             )}
            //           </span>
            //         </div>
            //       ) : x?.original_price > x?.plan_price ? (
            //         <div className="ruppetxt">
            //           <span>{`${x.plan_currency_symbol} ${x.original_price}`}</span>{" "}
            //           {`${x.plan_currency_symbol} ${x.plan_price}`}
            //           <span className="plnval">
            //             {langConvrt(
            //               Capitalize(x.plan_name),
            //               plansData?.defaultlang,
            //               plansData?.language
            //             )}
            //           </span>
            //         </div>
            //       ) : (
            //         <div className="ruppetxt">
            //           {`${x.plan_currency_symbol} ${x.plan_price}`}
            //           <span className="plnval">
            //             {langConvrt(
            //               Capitalize(x.plan_name),
            //               plansData?.defaultlang,
            //               plansData?.language
            //             )}
            //           </span>
            //         </div>
            //       )}
            //       {/* Make "See Benefits" button functional */}
            //       <span
            //         className="seeBenefits"
            //         onClick={(e) => {
            //           e.stopPropagation(); // Prevent interference from parent div
            //           setSeeBenefit(true); // Trigger the popup
            //         }}
            //         style={{ zIndex: 10, position: "relative" }} // Ensure it's on top
            //       >
            //         {plansData?.language?.plan_see_benefits_button_text}
            //       </span>
            //     </div>
            //     <label className="containern">
            //       <input
            //         type="radio"
            //         name="radio"
            //         onClick={(e) => {
            //           e.stopPropagation(); // Prevent interference from parent div
            //           updateMultipleFormData({
            //             plan_id: x.plan_id,
            //             plan_name: x.plan_name,
            //             plan_price: x.plan_price,
            //             plan_currency: x.plan_currency,
            //             plan_duration: x.duration,
            //           });
            //           callupdate({
            //             plan_id: x.plan_id,
            //             plan_name: x.plan_name,
            //             plan_price: x.plan_price,
            //             plan_currency: x.plan_currency,
            //             plan_duration: x.duration,
            //           });
            //         }}
            //         defaultChecked={x?.is_recommended}
            //       />
            //       <span className="checkmark"></span>
            //     </label>
            //   </div>
            // );
//                 return(
//                   <div
//   className={`bxplan ${x?.isShow === false ? "in-active-plan" : ""}`}
//   key={i}
//   onClick={() => setSelectedPlan(x)} // Handles plan selection
// >
//   {/* Free Trial */}
//   {x.is_recommended && (
//     <>
//       <div className="rcomm" style={{ display: "none" }}>
//         {x?.plan_id === 10
//           ? "Loot Deal"
//           : plansData?.language?.pay_recommended_text}
//       </div>
//       <div>
//         <img
//           className="gifimg"
//           alt="pay-gif"
//           src="images/mostPopular.png"
//         />
//       </div>
//     </>
//   )}
//   {x.freeTrialPlanRecText && (
//     <div className="rcomm">
//       {plansData?.language?.pay_trialoffer_text}
//     </div>
//   )}
//   <div className="paddpan">
//     <p className="typeplan">
//       {langConvrt(
//         x.plan_name,
//         plansData?.defaultlang,
//         plansData?.language
//       )}
//     </p>
  
//     {x.discountedPrice ? (
//       <div className="ruppetxt">
//         <span>{`${x.plan_currency_symbol} ${x.plan_price}`}</span>
//         {`${x.plan_currency_symbol} ${x.discountedPrice}`}
//         <span className="plnval">
//           {langConvrt(
//             Capitalize(x.plan_name),
//             plansData?.defaultlang,
//             plansData?.language
//           )}
//         </span>
//       </div>
//     ) : x?.original_price > x?.plan_price ? (
//       <div className="ruppetxt">
//         <span>{`${x.plan_currency_symbol} ${x.original_price}`}</span>{" "}
//         {`${x.plan_currency_symbol} ${x.plan_price}`}
//         <span className="plnval">
//           {langConvrt(
//             Capitalize(x.plan_name),
//             plansData?.defaultlang,
//             plansData?.language
//           )}
//         </span>
//       </div>
//     ) : (
//       <div className="ruppetxt">
//         {`${x.plan_currency_symbol} ${x.plan_price}`}
//         <span className="plnval">
//           {langConvrt(
//             Capitalize(x.plan_name),
//             plansData?.defaultlang,
//             plansData?.language
//           )}
//         </span>
//       </div>
//     )}
//     {/* Make "See Benefits" button functional */}
//     <span
//       className="seeBenefits"
//       onClick={(e) => {
//         e.stopPropagation(); // Prevent interference from parent div
//         setSeeBenefit(true); // Trigger the popup
//         setSelectedPlan(x); // Ensure plan is selected
//         updateMultipleFormData({
//           plan_id: x.plan_id,
//           plan_name: x.plan_name,
//           plan_price: x.plan_price,
//           plan_currency: x.plan_currency,
//           plan_duration: x.duration,
//         });
//         callupdate({
//           plan_id: x.plan_id,
//           plan_name: x.plan_name,
//           plan_price: x.plan_price,
//           plan_currency: x.plan_currency,
//           plan_duration: x.duration,
//         });
//       }}
//       style={{ zIndex: 10, position: "relative" }} // Ensure it's on top
//     >
//       {plansData?.language?.plan_see_benefits_button_text}
//     </span>
//   </div>
//   <label className="containern">
//     <input
//       type="radio"
//       name="radio"
//       onClick={(e) => {
//         e.stopPropagation(); // Prevent interference from parent div
//         setSelectedPlan(x); // Update selected plan
//         updateMultipleFormData({
//           plan_id: x.plan_id,
//           plan_name: x.plan_name,
//           plan_price: x.plan_price,
//           plan_currency: x.plan_currency,
//           plan_duration: x.duration,
//         });
//         callupdate({
//           plan_id: x.plan_id,
//           plan_name: x.plan_name,
//           plan_price: x.plan_price,
//           plan_currency: x.plan_currency,
//           plan_duration: x.duration,
//         });
//       }}
//       defaultChecked={x?.is_recommended}
//     />
//     <span className="checkmark"></span>
//   </label>
// </div>

//                 ) 
return(
  <div
  className={`bxplan ${x?.isShow === false ? "in-active-plan" : ""}`}
  key={i}
  onClick={() => {
    setSelectedPlan(x); // Handles plan selection
    updateMultipleFormData({
      plan_id: x.plan_id,
      plan_name: x.plan_name,
      plan_price: x.plan_price,
      plan_currency: x.plan_currency,
      plan_duration: x.duration,
    });
  }}
>
  {/* Free Trial */}
  {x.is_recommended && (
    <>
      <div className="rcomm" style={{ display: "none" }}>
        {x?.plan_id === 10
          ? "Loot Deal"
          : plansData?.language?.pay_recommended_text}
      </div>
      <div>
        <img
          className="gifimg"
          alt="pay-gif"
          src="images/mostPopular.png"
        />
      </div>
    </>
  )}
  {x.freeTrialPlanRecText && (
    <div className="rcomm">
      {plansData?.language?.pay_trialoffer_text}
    </div>
  )}
  <div className="paddpan">
    <p className="typeplan">
      {langConvrt(
        x.plan_name,
        plansData?.defaultlang,
        plansData?.language
      )}
    </p>
  
    {x.discountedPrice ? (
      <div className="ruppetxt">
        <span>{`${x.plan_currency_symbol} ${x.plan_price}`}</span>
        {`${x.plan_currency_symbol} ${x.discountedPrice}`}
        <span className="plnval">
          {langConvrt(
            Capitalize(x.plan_name),
            plansData?.defaultlang,
            plansData?.language
          )}
        </span>
      </div>
    ) : x?.original_price > x?.plan_price ? (
      <div className="ruppetxt">
        <span>{`${x.plan_currency_symbol} ${x.original_price}`}</span>{" "}
        {`${x.plan_currency_symbol} ${x.plan_price}`}
        <span className="plnval">
          {langConvrt(
            Capitalize(x.plan_name),
            plansData?.defaultlang,
            plansData?.language
          )}
        </span>
      </div>
    ) : (
      <div className="ruppetxt">
        {`${x.plan_currency_symbol} ${x.plan_price}`}
        <span className="plnval">
          {langConvrt(
            Capitalize(x.plan_name),
            plansData?.defaultlang,
            plansData?.language
          )}
        </span>
      </div>
    )}
    {/* Make "See Benefits" button functional */}
    <span
      className="seeBenefits"
      onClick={(e) => {
        e.stopPropagation(); // Prevent interference from parent div
        setSeeBenefit(true); // Trigger the popup
        setSelectedPlan(x); // Ensure plan is selected
        updateMultipleFormData({
          plan_id: x.plan_id,
          plan_name: x.plan_name,
          plan_price: x.plan_price,
          plan_currency: x.plan_currency,
          plan_duration: x.duration,
        });
      }}
      style={{ zIndex: 10, position: "relative" }} // Ensure it's on top
    >
      {plansData?.language?.plan_see_benefits_button_text}
    </span>
  </div>
  <label className="containern">
    <input
      type="radio"
      name="radio"
      onClick={(e) => {
        e.stopPropagation(); // Prevent interference from parent div
        setSelectedPlan(x); // Update selected plan
        updateMultipleFormData({
          plan_id: x.plan_id,
          plan_name: x.plan_name,
          plan_price: x.plan_price,
          plan_currency: x.plan_currency,
          plan_duration: x.duration,
        });
      }}
      checked={selectedPlan?.plan_id === x.plan_id} // Ensure the radio button reflects the selected plan
    />
    <span className="checkmark"></span>
  </label>
</div>

)
          })}
        </div>

        {/* <form onSubmit={(e) => verifyCoupon(e)}>
        <div
          className={`cpbxcon ${fieldData?.couponValidStatus === true && showApplyBtn === false
            ? "coupon-success-border"
            : fieldData?.couponValidStatus === false &&
              fieldData?.couponCode !== "" &&
              showApplyBtn === false
              ? "coupon-error-border"
              : ""
            }`}
        >
          <input
            type="text"
            value={fieldData?.couponCode}
            placeholder={plansData?.language?.pay_coupon_placeholder}
            onChange={(e) => handleCouponOnChange(e.target.value)}
          />
          {showApplyBtn === false ? (
            <img
              alt="close icon"
              src={
                process.env.REACT_APP_ASSETS_URL +
                "/web/images/coupon-error-close.svg"
              }
              class="error-closebtn"
              onClick={handleClearCoupon}
            />
          ) : (
            <button
              style={{ opacity: applyBtn === true ? 1 : "" }}
              className="apply_btn cpbtn"
              type="submit"
            >
              {plansData?.language?.pay_coupon_apply_text}
            </button>
          )}
          {fieldData.couponValidStatus === false &&
            fieldData?.couponCode !== "" &&
            fieldData?.isCouponExpired === false &&
            showApplyBtn === false && (
              <p class="coupon-error-msg error-txt">
                {plansData?.language?.pay_coupon_invalid_text}
              </p>
            )}
          {fieldData.couponValidStatus === true &&
            fieldData?.couponCode !== "" &&
            fieldData?.isCouponExpired === false &&
            showApplyBtn === false && (
              <p class="coupon-success-msg error-txt">
                {plansData?.language?.pay_coupon_valid_text}
              </p>
            )}
          {fieldData.couponValidStatus === false &&
            fieldData?.isCouponExpired === true &&
            fieldData?.couponCode !== "" &&
            showApplyBtn === false && (
              <p class="coupon-error-msg error-txt">
                {plansData?.language?.pay_coupon_expired_text}
              </p>
            )}
        </div> */}
        {/* {fieldData.validStatus && <div class="cpbxcon"><span style={{ color: "red", fontWeight: "bold" }}>{"Code Is Invalid"}</span></div>} */}
        {/* </form> */}

        <div className="cpbtnsb">
          <input type="hidden" id="x_plan_name" />
          <input type="hidden" id="x_plan_price" />
          {/* <a href="!#" onClick={(e) => gaEventTracker(fieldData.plan_name)&& buyGold(e)} className="goldbtn"> */}
          <a onClick={() => planDetailsId === "960" ? null : selectedPlan && planDetailsId !== "960" ? setSeeSubcribe(true) : null} className={planDetailsId === "960" ? "goldbtn disablegoldbtn" : "goldbtn"}>
            <Image src="goldimg.svg" alt="golimg" />
            {/* {plansData?.language?.pay_buyplan_btn_text} */}
            {/* Subscribe */}
            {plansData?.language?.pay_subscribe_button_txt}
          </a>
          {/*<a href="!#" className="asubbtn">
                Already have subcription
    </a>*/}
        </div>
        {/* <p className="premiumText">Premium subscription auto-renewal . Cancel Anytime</p> */}
        <p className="premiumText">{plansData?.language?.plan_disclaimer_text}</p>
        {/* <p className="termsConditions">Terms & Conditions</p> */}
        <p className="termsConditions" onClick={() => redirectTC()}>{plansData?.language?.terms_and_condition}</p>
      </div>
    </>
  );
}
