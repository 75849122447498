import React, { useState, useEffect } from "react";
import ReactGA4 from "react-ga4";
import ReactGA from "react-ga";
// import slide1 from "../img/slide1.png";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import get from "lodash.get";
// import { backendApiUrl } from "./utils";
import {
  amplitudeBtnEvent,
  ampRabbitMQBtnEvent,
  amplitudePageEvent,
  ampRabbitMQPageEvent,
  amplitudeCustomEvent,
  ampRabbitMQCustomEvent,
  ampRabbitMQsubBtnClickPageEvent,
  langConvrt,
} from "../../utils";

import { language, checkFreeTrialStatus, subscribePlan, confirmOtp } from "../../utils/services";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { freeTrialPlanData, SUCCESS } from "../../../constants";
// import  { install } from 'ga-gtag';
import { getJusPayCustomer, get_jus_pay_identity } from "../../utils/juspay";
import { Image } from "../../desktop/subcomponents/Elements";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export function Plan() {
  // const ref = useRef();

  let currenturl = useLocation().search;
  const couponcode = new URLSearchParams(currenturl).get("couponcode");
  const plan_id = new URLSearchParams(currenturl).get("plan_id");
  const product_id = new URLSearchParams(currenturl).get("product_id");
  const platform_id = new URLSearchParams(currenturl).get("platform_id");
  const country = new URLSearchParams(currenturl).get("country");
  const identity = new URLSearchParams(currenturl).get("identity");
  const plan_type = new URLSearchParams(currenturl).get("plan_type");
  const hardware_id = new URLSearchParams(currenturl).get("hardware_id");
  const lan = new URLSearchParams(currenturl).get("lang");
  const npay_redirect = new URLSearchParams(currenturl).get("npay_redirect");
  const npay_campaignsource = new URLSearchParams(currenturl).get(
    "npay_campaignsource"
  );
  const test = new URLSearchParams(currenturl).get("test");
  const campaignsource = new URLSearchParams(currenturl).get("campaignsource");
  const npay_affilaite = new URLSearchParams(currenturl).get("npay_affilaite");
  const aff_id = new URLSearchParams(currenturl).get("aff_id");
  const planDetailsId = new URLSearchParams(currenturl).get("plan_details_id");
  const utm_source = new URLSearchParams(currenturl).get("utm_source");
  const source = new URLSearchParams(currenturl).get("source");
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  const GA4_TRACKING_ID = `${process.env.REACT_APP_GA4_CODE}`; // OUR_GA4_TRACKING_ID


  const [jusPayIdentity, setjusPayIdentity] = useState({});
  useEffect(async () => {
    const getJusPayUser = await get_jus_pay_identity(identity);
    if (getJusPayUser.message_code == 'GET_CUSTOMER_SUCCESS') {
      jusPayIdentity.juspay_identity = getJusPayUser.juspay_identity;
      jusPayIdentity.hungama_identity = getJusPayUser.identity;
      setjusPayIdentity({ ...jusPayIdentity });
      localStorage.setItem('juspay_user', JSON.stringify(jusPayIdentity));
    }
    else {
      const createJusPayUser = await getJusPayCustomer(identity);
      jusPayIdentity.juspay_identity = createJusPayUser.juspay_identity;
      jusPayIdentity.hungama_identity = createJusPayUser.identity;
      setjusPayIdentity({ ...jusPayIdentity });
      localStorage.setItem('juspay_user', JSON.stringify(jusPayIdentity));
    }
  }, []);
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  // const urlparams = currenturl.replace("?", "");

  // const textData1 =

  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  let history = useHistory();
  const goToPreviousPath = () => {
    //amplitudeBtnEvent(identity, "Btn_Back_Plan", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Back_Plan"
    );
    if (platform_id === "1" && isAndroid) {
      window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
    }
    history.goBack();
  };
  const [openCoupon, setOpenCoupon] = useState(false);

  const [formData, setFormData] = useState({
    couponError: false,
    plan_id: "",
    couponcode: "",
    currenturl: currenturl,
    couponStatus: 0,
  });
  const [freeCouponData, setFreeCouponData] = useState({});

  // const [couponApplied, setCouponApplied] = useState(true);
  const [applyBtn, setApplyBtn] = useState(false);
  const [showApplyBtn, setShowApplyBtn] = useState(true);

  const [plans, setPlans] = useState([]);
  const [oldPlans, setOldPlans] = useState([]);

  // const [sliders, setSlider] = useState([]);
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [textData, setTextData] = useState({});
  const [defaultTextData, setDefaultTextData] = useState({});
  const [freeTrialData, setFreeTrialData] = useState({});
  const [upgrade,setUpgrade] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState();
  const [pushbar, setPushbar] = useState({
    seeBenefit: false,
    seeSubcribe: false,
    seeOtp: false,
    seeCongratulation: false,
    seePaymentFailed: false,
  });
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [resendAvailable, setResendAvailable] = useState(false);
  const [subcribeResponse, setSubcribeResponse] = useState({});
  const [timer, setTimer] = useState(900);
  const [intervalId, setIntervalId] = useState(null);

  /*
  const handleCoupenClick = () => {
    // amplitudeBtnEvent(identity, "Btn_Open_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Open_Coupon"
    );
    // setOpenCoupon(true);
    setOpenCoupon((perValue) => !perValue);
  };
  */

  const handleCouponCancel = (identity) => {
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Remove_Coupon"
    );
    // history.push("plan?"+urlparams);
    // history.go(0);
  };
  const redirectTC = () =>{
    window.open(process.env.REACT_APP_TERMS_PAGE, "_blank");
  }
  useEffect(() => {
    if (isLoadingOnce) {
      /* 
      amplitudePageEvent(
        identity,
        {
          "Page Type": "Plan Page",
          Source: source ? source : "N/A",
          "Affiliate ID": aff_id ? aff_id : "",
          "Page Language": lan ? lan : "en",
        },
        product_id,
        platform_id
      );
      */
      /* amplitude with RabbitMQ */
      ampRabbitMQPageEvent({
        identity: identity ? identity : "",
        "Page Type": "Plan Page",
        Source: source ? source : "N/A",
        "Affiliate ID": aff_id ? aff_id : "",
        "Page Language": lan ? lan : "en",
        product_id: product_id ? product_id : "",
        platform_id: platform_id ? platform_id : "",
        device: "mobile"
      });

      const logData = {
        url: hostname + pathname,
        params: currenturl,
        couponcode: couponcode,
        plan_id: plan_id,
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
        plan_type: plan_type,
        hardware_id: hardware_id,
      };

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const data = JSON.stringify(logData);
      const body = {
        logData: data,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });

      language(lan).then((res) => {
        //set Lang conditionally
        // setTextData(res);
        setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res["secondLang"] : res["default"]);
        // console.log(res);
      });
    }
    setLoadingOnce(false);
  }, [
    isLoading,
    hostname,
    pathname,
    currenturl,
    couponcode,
    plan_id,
    product_id,
    isLoadingOnce,
    platform_id,
    country,
    identity,
    plan_type,
    utm_source,
    source,
    aff_id,
    hardware_id,
    lan,
  ]);

  // Coupon
  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (openCoupon && ref.current && !ref.current.contains(e.target)) {
  //       setOpenCoupon(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [openCoupon]);

  const handleForm = (e, pdata) => {
    const { name, value } = e.target;
    if (name !== "couponcode") {
      //console.log(value)
      var valarr = value.split(":");
      // console.log("pln select",identity,valarr[0],valarr[2])
      if (pdata && identity) {
        /*
        amplitudeCustomEvent(
          identity,
          "npay_click_planselect",
          {
            npay_currency: pdata?.plan_currency,
            npay_planid: pdata?.plan_id,
            npay_planname: pdata?.plan_name,
            npay_plan_validity:
              pdata?.duration?.duration + " " + pdata?.duration?.text,
            npay_planvalue: pdata?.plan_price,
          },
          product_id,
          platform_id
        );
        */
        ampRabbitMQCustomEvent(
          {
            identity: identity,
            npay_currency: pdata?.plan_currency,
            npay_planid: pdata?.plan_id,
            npay_planname: pdata?.plan_name,
            npay_plan_validity:
              pdata?.duration?.duration + " " + pdata?.duration?.text,
            npay_planvalue: pdata?.plan_price,
            product_id: product_id,
            platform_id: platform_id,
          },
          "npay_click_planselect"
        );
        // console.log("onSelectData:", pdata);
      }

      gaEventTracker("pick_plan", valarr[0] + "_" + valarr[2]);
      document.getElementById("x_plan_name").value = valarr[0];
      document.getElementById("x_plan_price").value = valarr[2];

      // setFormData((prev) => {
      //     return { prev, [name]: value };
      // })
      let data = { ...formData, plan_id: pdata?.plan_id };
      data[name] = valarr[1];
      setFormData(data);
    } else if (name === "couponcode") {
      value !== "" ? setApplyBtn(true) : setApplyBtn(false);
      let data = { ...formData };
      data[name] = value;
      setFormData(data);
    }
  };

  const gaEventTracker = useAnalyticsEventTracker("Payment Plan");

  const buygold = (e) => {
    // amplitudeBtnEvent(
    //   identity,
    //   "Btn_BottomFix_Buy Now",
    //   product_id,
    //   platform_id
    // );
    ampRabbitMQsubBtnClickPageEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_BottomFix_Buy Now"
    );
    var plan_name_price =
      document.getElementById("x_plan_name").value +
      "_" +
      document.getElementById("x_plan_price").value;
    gaEventTracker("buy_hungama_gold", plan_name_price);
    if (formData.plan_id !== "") {
      if (formData.plan_id === "37" || formData.plan_id === 37) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("plan_type", "event");
        currenturl = searchParams.toString();
      }
      // console.log(currenturl);
      //Free Coupon
      // console.log(freeCouponData);
      if (
        freeCouponData?.status === 2 &&
        freeCouponData?.coupon_details?.type === "free"
      ) {
        let obj = {
          couponCode: freeCouponData?.coupon_code,
          status: freeCouponData?.status,
          ...freeCouponData?.coupon_details,
        };
        let { couponCode, plan_details_id } = obj;
        // console.log(
        //   "CCCC:::",
        //   `${process.env.REACT_APP_HANGAMA_URL
        //   }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${formData?.plan_id
        //   }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`
        // );
        // return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${
        //   formData?.plan_id
        // }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`);
      } else if (
        freeCouponData?.status === 1 &&
        freeCouponData?.coupon_details?.type === "free" &&
        freeCouponData?.coupon_details?.plansApplicable?.includes(
          `${formData?.plan_id}`
        )
      ) {
        let obj = {
          couponCode: freeCouponData?.coupon_code,
          status: freeCouponData?.status,
          ...freeCouponData?.coupon_details,
        };
        let { couponCode, plan_details_id } = obj;
        return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL
          }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${formData?.plan_id
          }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`);
      }

      history.push(
        `/payment?plan_id=${formData.plan_id}&couponcode=${formData.couponcode === undefined ? "" : formData?.couponcode
        }&${currenturl.replace("?", "")}`
      );
    } else {
      alert("please select a plan");
    }
  };

  // const freeCoupon = async (coupon_code) => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_HANGAMA_URL}/coupons/index.php?reqfrom=app&identity=${identity}&coupon_code=${coupon_code}`
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       const order_id = get(res, "data.order_id", "");
  //       if (res.data.status === 200) {
  //         history.push(`/payment_success?order_id=${order_id}`);
  //       }
  //       return;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleClearCoupon = (e, identity) => {
    setShowApplyBtn(true);
    // setFormData((prev) => {
    //   return { ...prev, couponcode: "" };
    // });
    setFormData((prev) => {
      return {
        ...prev,
        couponcode: "",
        couponError: false,
        couponStatus: 0,
      };
    });
    setFreeCouponData({});
    setPlans(oldPlans);
    handleCouponCancel(identity);
    //apply btn
    setApplyBtn(false);
  };

  const verifyCoupon = (e) => {
    if (e) e.preventDefault();
    // amplitudeBtnEvent(identity, "Btn_Submit_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_Coupon"
    );
    gaEventTracker(
      "apply_coupon",
      "HUNGAMA_" + document.getElementById("couponcode").value
    );
    axios
      .get(
        `${process.env.REACT_APP_HANGAMA_URL}/coupons/get_discounted_value.php?coupon_code=${formData?.couponcode}`
      )
      .then((res) => {
        // console.log("coupon res:", res);
        const couponval = get(res, "data.coupon_details.value", "");
        const coupontype = get(res, "data.coupon_details.type", "");
        const couponStatus = get(res, "data.status", "");
        const plansApplicable = get(
          res,
          "data.coupon_details.plansApplicable",
          ""
        );

        // console.log("coupon" + couponval);
        // console.log("coupontype" + coupontype);
        // console.log("couponStatus" + couponStatus, typeof couponStatus);
        if (couponval !== "" && couponStatus !== "" && couponStatus !== -1) {
          setFormData((prev) => {
            return {
              ...prev,
              couponError: false,
              couponStatus: couponStatus,
            };
          });
          setShowApplyBtn(false);
          setOpenCoupon(false);

          let newPlans = [];
          plans.map((e) => {
            let discount = "",
              showActive = false,
              pair = {};
            if (
              coupontype === "discounted" &&
              plansApplicable.includes(`${e?.plan_id}`)
            ) {
              discount = Math.round(
                Number(e?.plan_price) -
                (Number(couponval) / 100) * Number(e?.plan_price)
              );
              if (discount >= 0) {
                pair = { discountedPrice: discount, showActive: true };
              }
            } else if (
              coupontype === "flat" &&
              plansApplicable.includes(`${e?.plan_id}`)
            ) {
              discount = Math.round(Number(e?.plan_price) - Number(couponval));
              if (discount >= 0) {
                pair = { discountedPrice: discount, showActive: true };
              }
            } else if (coupontype === "free" && couponStatus === 2) {
              // Halfyearly hiddine plan
            } else if (
              coupontype === "free" &&
              plansApplicable.includes(`${e?.plan_id}`)
            ) {
              pair = {
                discountedPrice: Number(couponval).toFixed(),
                is_recommended: true,
                showActive: true,
              };
              // set freeCouponData
              setFreeCouponData(res?.data);
              // discount = Number(couponval).toFixed();
            } else {
              pair = { showActive };
            }
            // if (Number(discount) <= 0 && coupontype !== "free") {
            //   e;
            // } else if (Number(discount) <= 0 && coupontype === "free") {
            //   e.discountedPrice = discount;
            //   e.is_recommended = true;
            // } else {
            //   e.discountedPrice = discount;
            // }
            //Handling Zero or minus value
            // let pair = {};
            // if (discount <= 0 && coupontype !== "free") {
            //   pair = {};
            // } else if (discount <= 0 && coupontype === "free") {
            //   pair = {
            //     discountedPrice: discount,
            //     is_recommended: true,
            //     showActive: true,
            //   };
            //   setFreeCouponData(res?.data);
            // } else if (discount <= 0) {
            // } else {
            //   pair = { discountedPrice: discount, showActive: false };
            // }
            e = { ...e, ...pair };
            newPlans.push(e);
            pair = {};
            discount = "";
            showActive = false;
          });
          // console.log(plans);
          setPlans(newPlans);
          if (coupontype === "free" && couponStatus === 2) {
            // hidden plan half yearly- assign direct subscription
            //Free Counpon for hidden plan
            let obj = {
              couponCode: res?.data?.coupon_code,
              status: res?.data?.status,
              ...res?.data?.coupon_details,
            };

            let { couponCode, plan_details_id } = obj;

            return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL
              }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${formData?.plan_id
              }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`);
          }
        } else {
          setShowApplyBtn(false);
          setFormData((prev) => {
            return {
              ...prev,
              couponError: true,
              couponStatus: couponStatus,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var bool = [];

  // if (this.state.boolean) {
  // bool = (<div><h1>True</h1></div>);
  // }
  // &#x20B9;
  for (let df = 0; df < plans?.length; df++) {
    //free trial plan amt change

    const data = plans[df];
    if (df % 2) {
      continue;
    }
    let data1 = "";
    if (df + 1 !== "") {
      data1 = plans[df + 1];
    }
    // for (const data of plans) {
    bool.push({ data: data, data1: data1 });
    // bool.push(
    //   <SwiperSlide className={`planSwiperSlider ${bool?.length===2?'plan-four-box':''} `}  key={df}>
    //     <label className="container">
    //       <div className="bxplan">
    //         {data.is_recommended && (
    //           <div className="rcomm">
    //             {textData?.secondLang?.pay_recommended_text
    //               ? textData?.secondLang?.pay_recommended_text
    //               : textData?.default?.pay_recommended_text}
    //           </div>
    //         )}
    //         {/* {data.is_recommended &&
    //                         setFormData((prev) => {
    //                             return {
    //                                 ...prev, plan_id: data.plan_id
    //                             }
    //                         })
    //                         // setFormData(data.plan_id)
    //                     } */}
    //         <div className="paddpan">
    //           <p className="typeplan">{data.plan_name}</p>
    //           {data.discountedPrice ? (
    //             <div className="ruppetxt">
    //               {" "}
    //               <span>
    //                 {data.plan_currency_symbol}
    //                 {data.plan_price}
    //               </span>
    //               {data.plan_currency_symbol}
    //               {data.discountedPrice}
    //             </div>
    //           ) : (
    //             <div className="ruppetxt">
    //               {data.plan_currency_symbol} {data.plan_price}
    //             </div>
    //           )}
    //         </div>
    //         {/* <label className="container"> */}
    //         <input
    //           type="radio"
    //           defaultChecked={data.is_recommended && "defaultChecked"}
    //           value={
    //             data.plan_name + ":" + data.plan_id + ":" + data.plan_price
    //           }
    //           name="plan_id"
    //           onChange={(e) => handleForm(e,data)}
    //         />
    //         <span className="checkmark"></span>
    //       </div>
    //     </label>
    //     {data1 && (
    //       <label className="container">
    //         <div className="bxplan">
    //           {data1.is_recommended && (
    //             <div className="rcomm">
    //               {textData?.secondLang?.pay_recommended_text
    //                 ? textData?.secondLang?.pay_recommended_text
    //                 : textData?.default?.pay_recommended_text}
    //             </div>
    //           )}
    //           {/* {data1.is_recommended && setFormData(data.planid = data1.plan_id)} */}

    //           <div className="paddpan">
    //             <p className="typeplan">{data1.plan_name}</p>
    //             {data1.discountedPrice ? (
    //               <div className="ruppetxt">
    //                 <span>
    //                   {data1.plan_currency_symbol}
    //                   {data1.plan_price}
    //                 </span>
    //                 {data1.plan_currency_symbol}
    //                 {data1.discountedPrice}
    //               </div>
    //             ) : (
    //               <div className="ruppetxt">
    //                 {data1.plan_currency_symbol} {data1.plan_price}
    //               </div>
    //             )}
    //           </div>
    //           {/* <label className="container"> */}
    //           <input
    //             type="radio"
    //             defaultChecked={data1.is_recommended && "defaultChecked"}
    //             value={
    //               data1.plan_name + ":" + data1.plan_id + ":" + data1.plan_price
    //             }
    //             name="plan_id"
    //             onChange={(e) => handleForm(e,data1)}
    //           />
    //           <span className="checkmark"></span>
    //         </div>
    //       </label>
    //     )}
    //   </SwiperSlide>
    // );
  }

  // const lastBoolCss={
  //   marginLeft:`${bool.length===2?"0%":"20%"}`,
  //   width:`${bool.length===2?"46%":"42%"}`,
  //   marginRight:`${bool.length===2?"4%":"15px"}`,
  // }
  // console.log("bool::", bool);
  // bool[bool?.length-1]?.props?.style={...lastBoolCss};
  // console.log("last bool::", bool[bool.length - 1]);

  useEffect(() => {
    //free trial
    if (freeTrialPlanData?.isActive === true) {
      checkFreeTrialStatus({ product_id, country, identity }).then((res) => {
        if (res?.status === SUCCESS) {
          //console.log("freetrial success!", res);
          if (
            res?.data?.free_trial_status === false &&
            freeTrialPlanData?.country === country &&
            freeTrialPlanData?.product_id === product_id
          ) {
            let obj = { ...freeTrialPlanData, status: false };
            setFreeTrialData(obj);
            //  localStorage.setItem('user_freetrial_mob',JSON.stringify(obj));
          } else {
            let obj = { ...freeTrialPlanData, status: true };
            setFreeTrialData(obj);
          }
        }
      });
    } else {
      let obj = { ...freeTrialPlanData, status: true };
      setFreeTrialData(obj);
    }
  }, [country, identity, product_id]);

  // useEffect(() => {
  //   console.log("freeTrialData::", freeTrialData);
  // }, [freeTrialData]);

  useEffect(() => {
    if (isLoading && Object.keys(freeTrialData)?.length > 0) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
      };
      // axios
      //     .get(`${process.env.REACT_APP_API_URL}/page/index.php?c=api&m=get_plan_option`)
      let domain;
      if (test && test != null && test != undefined && test == 'true') {
        domain = process.env.REACT_APP_CDN_API_URL
      }
      else {
        domain = process.env.REACT_APP_API_URL
      }
      axios({
        method: "GET",
        url: `${domain}/v1/billing/plans?product_id=${body.product_id}&platform_id=${body.platform_id}&country=${body.country}&identity=${body.identity}`,
        headers: headers
        // data: body,
      })
        .then((res) => {
          //Free Trial start
          if (freeTrialData?.status === false) {
            //free trial plan amt
            const rowToChange = res?.data?.plans?.findIndex((item) => {
              return item.plan_id === Number(freeTrialData?.plan_id);
            });
            res.data.plans[rowToChange].plan_price = freeTrialData?.plan_amt;
            res.data.plans[rowToChange].is_recommended = true;
            res.data.plans[rowToChange].freeTrialPlanRecText =
              freeTrialData?.freeTrialPlanRecText;
          }
          //Free Trial end
          let plansdata = res?.data?.plans;
          // const sliderInfodata = res.data.sliderInfo;
          let recommendedplan_id;
          plansdata.forEach((x, i) => {
            if (x.is_recommended) {
              recommendedplan_id = x.plan_id;
            }
          });

          setFormData((prev) => {
            return {
              ...prev,
              plan_id: recommendedplan_id,
            };
          });

          if (plansdata) {
            let isAfterMatch = false;

            plansdata = plansdata.map(plan => {
              if (plan.plan_details_id == planDetailsId || isAfterMatch) {
                isAfterMatch = true;
                return {
                  ...plan,
                  isShow: false
                };
              }
              return {
                ...plan,
                isShow: true
              };
            })
          }

          // setSlider(sliderInfodata);
          setPlans(plansdata);
          setOldPlans(plansdata);
          // console.log('coming')
          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });

      // var requestOptions = {
      //   method: "GET",
      //   redirect: "follow",
      // };

      // fetch("https://ipapi.co/json/", requestOptions)
      //   .then((response) => response.text())
      //   .then((res) => {
      // const result = JSON.parse(res);
      // // return '';
      // let campaignsourceval, affilaite;
      // if (!npay_campaignsource) { campaignsourceval = campaignsource; }
      // if (!npay_affilaite) { affilaite = aff_id; }
      /* Rabbit MQ */
      // const rabbitMQData = {
      //     'identity': identity,
      //     'product_id': product_id,
      //     'auth-key': process.env.REACT_APP_MQ_AUTH_KEY,
      //     'event_type': 'npay_page_plans',
      //     'event_time': Math.floor(new Date().getTime() / 1000),
      //     'queue_name': process.env.REACT_APP_QUEUE_NAME,
      //     'ip': result.ip,
      //     'country': result.country,
      //     'event_properties': {
      //         'npay_redirect': 'false',
      //         'campaignsource': campaignsourceval,
      //         'affilaite': affilaite,
      //     }
      // }
      // console.log(rabbitMQData);
      // axios({
      //     method: "POST",
      //     url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/receiver`,
      //     headers: headers,
      //     data: rabbitMQData
      // })
      //     .then((res) => {
      //         console.log('rabbitmq')
      //         console.log(res);
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //     });
      // })
      // .catch((error) => console.log("error", error));
    }
  }, [
    identity,
    npay_redirect,
    aff_id,
    campaignsource,
    npay_affilaite,
    npay_campaignsource,
    isLoading,
    product_id,
    platform_id,
    country,
    freeTrialData,
  ]);

  useEffect(() => {
    if (openCoupon) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [openCoupon]);

  // useEffect(()=>{
  //   install('UA-217401863-1',{ 'send_page_view': false });
  // },[])
  // console.log("freeCouponData", freeCouponData);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
    setError("");
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
    setError("");
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (/^\d+$/.test(paste) && paste.length === otp.length) {
      const newOtp = paste.split("");
      setOtp(newOtp);
      newOtp.forEach((digit, index) => {
        document.getElementById(`otp-input-${index}`).value = digit;
      });
    }
    e.preventDefault();
    setError("");
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleResendOtp = () => {
    setError("");
    setResendAvailable(false);
    setTimer(300);
    handleConfirm();
  };

  const handleConfirm = async () => {
    const response = await subscribePlan({
      product_id: product_id,
      platform_id: platform_id,
      country: country,
      identity: identity,
      payment_id: 15,
      plan_details_id: planDetailsId || selectedPlan?.plan_details_id,
      lang: lan,
      ...(planDetailsId ? { upgrade_plan_details_id: selectedPlan?.plan_details_id } : {})
    })
    if(response.status === "success" && response.data.upgrade_status === true){
      setUpgrade(true)
      setPushbar((prev) => {
        return {
          ...prev,
          seeOtp: false,
          seeCongratulation: true
        }
      })
    }
   else if (response.status === "success") {
      setPushbar((prev) => { return { ...prev, seeSubcribe: false, seeOtp: true } })
      setSubcribeResponse(response.data.info)
    } else {
      setError(response.data.error_details);
    }
  }

  const handleVerifyOtp = async () => {
    const response = await confirmOtp({
      product_id: product_id,
      platform_id: platform_id,
      country: country,
      identity: identity,
      payment_id: 15,
      otp: Number(otp.join("")),
      id: subcribeResponse?.id,
      token: subcribeResponse?.token,
      plan_details_id: planDetailsId || selectedPlan?.plan_details_id,
      lang: lan,
      ...(planDetailsId ? { upgrade_plan_details_id: selectedPlan?.plan_details_id } : {})
    })
    if (response.status === "success") {
      setPushbar((prev) => {
        return {
          ...prev,
          seeOtp: false,
          seeCongratulation: true
        }
      })
    } else {
      setError(response.data.error_details);
    }
  };

  const startCountdown = () => {
    const newIntervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          setResendAvailable(true);
          clearInterval(newIntervalId);
          return 0;
        }
      });
    }, 1000);
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    if (pushbar.seeBenefit) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.height = "100vh";
      document.body.style.width = "100%";
    } else if (!pushbar.openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position");
      document.body.style.removeProperty("height");
      document.body.style.removeProperty("width");
    }
  }, [pushbar]);

  useEffect(() => {
    if (pushbar.seeOtp) {
      setResendAvailable(false);
      setTimer(300);
      startCountdown();
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [pushbar]);

  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div style={{
            pointerEvents:
              pushbar.seeBenefit
                ? "none"
                : "auto",
          }}>
            {/* <Swiper
              pagination={{ clickable: true }}
              className={"swiper1"}
              autoplay={{ delay: 3000 }}
            > */}
            {/* {sliders.length > 0 &&
                sliders.map((e, i) => (
                  <SwiperSlide key={i}>
                    <div className="swiper-slide">
                      <div className="slidiv">
                        <img src={e.img} alt="slider" />
                      </div>
                      {e.slider_type === "gold" ? (
                        <div className="goldicnslid"></div>
                      ) : (
                        <div className="noaddslid"></div>
                      )}
                      <div className="slidftxt">{e.slidftxt}</div>
                      <div className="slidstxt">{e.slidstxt}</div>
                    </div>
                  </SwiperSlide>
                ))} */}
            {/* static code for banner slide */}
            {/* <SwiperSlide>
                <div className="swiper-slide">
                  <div className="slidiv">
                    <img
                      src={`https://images1.hungama.com/ios_payment_album1.png`}
                      alt="slider"
                    />
                  </div>
                  <div className="goldicnslid"></div>
                  <div className="slidftxt">{textData?.pay_banner_title_1}</div>
                  <div className="slidstxt">
                    {textData?.pay_banner_subtitle_1}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide> */}
            <div className="swiper-slide">
              <div className="slidiv">
                <img
                  src="images/mobileBg.png"
                  alt="slider"
                />
              </div>
              {/* <div className="noaddslid"></div> */}
              {/* <div className="slidftxt">Level up your experience by subscribing
                to Premium!</div> */}
              <div className="slidftxt">{textData?.pay_banner_title_1}</div>
              <div className="slidstxt">
                {/* <p className="slidstxt1"><img src="images/star.png" alt="star image" />Enjoy High Quality Contents</p>
                <p className="slidstxt2"><img src="images/star.png" alt="star image" />Watch all Contents & Features</p> */}
                <p className="slidstxt1"><img src="images/star.png" alt="star image" />{textData?.pay_banner_subtitle_1}</p>
                <p className="slidstxt2"><img src="images/star.png" alt="star image" />{textData?.pay_banner_subtitle_2}</p>
              </div>
            </div>
            {/* </SwiperSlide>
            </Swiper> */}
            <div className="plans" id="planstart">
              <div className="planmar">
                {/* <Swiper className="planSwiper" enabled={bool?.length===2?false:true}>{bool}</Swiper> */}
                <Swiper
                  className={`planSwiper`}
                  enabled={bool?.length === 2 ? false : true}
                >
                  {bool?.map((el, index) => {
                    return (
                      // <SwiperSlide
                      //   className={`planSwiperSlider ${bool?.length === 2 ? "plan-four-box" : ""
                      //     } `}
                      //   key={index}
                      // >
                      //   <label
                      //     className={`container ${el?.data?.isShow === false
                      //       ? "in-active-plan"
                      //       : ""
                      //       }
                      //     `}
                      //     onClick={() => {
                      //       setSelectedPlan(el?.data)
                      //       setPushbar((prev) => {
                      //         return {
                      //           ...prev,
                      //           seeBenefit: true,
                      //         }
                      //       })
                      //     }}
                      //   >
                      //     <div
                      //       className={`bxplan ${el?.data?.plan_id === 37 ? "adsFree-lg-box" : ""
                      //         } 
                      //       `}
                      //     >
                      //       {el?.data.is_recommended && (
                      //         <>
                      //           <div
                      //             className="rcomm"
                      //             style={{ display: "none" }}
                      //           >
                      //             {el?.data?.plan_id === 10
                      //               ? "Loot Deal"
                      //               : textData?.pay_recommended_text}
                      //             {/* {textData?.pay_recommended_text} */}
                      //           </div>
                      //           <div>
                      //             <img
                      //               className="gifimg"
                      //               alt="pay-gif"
                      //               // src={
                      //               //   process.env.REACT_APP_ASSETS_URL +
                      //               //   "/mobile/images/holi_tag.svg"
                      //               // }
                      //               // src={"https://images.hungama.com/c/uploads_hungama/20240910/20240910062219_festive_offer.png"}
                      //               src="images/mostPopular.png"
                      //             />
                      //           </div>
                      //         </>
                      //       )}
                      //       {/* Free Trial */}
                      //       {el?.data?.freeTrialPlanRecText && (
                      //         <div className="rcomm">
                      //           {/* {el?.data?.freeTrialPlanRecText} */}
                      //           {textData?.pay_trialoffer_text}
                      //         </div>
                      //       )}
                      //       {/* {data.is_recommended &&
                      //                           setFormData((prev) => {
                      //                               return {
                      //                                   ...prev, plan_id: data.plan_id
                      //                               }
                      //                           })
                      //                           // setFormData(data.plan_id)
                      //                       } */}
                      //       <div className="paddpan">
                      //         <p className="typeplan">
                      //           {langConvrt(
                      //             el?.data.plan_name,
                      //             defaultTextData,
                      //             textData
                      //           )}
                      //         </p>
                      //         {el?.data.discountedPrice ? (
                      //           <div className="ruppetxt">
                      //             <span>
                      //               {el?.data.plan_currency_symbol}
                      //               {el?.data.plan_price}
                      //             </span>
                      //             {el?.data.plan_currency_symbol}
                      //             {el?.data.discountedPrice}
                      //           </div>
                      //         ) : //PLAN PRICE CHANGE EFFECT
                      //           el?.data?.original_price >
                      //             el?.data?.plan_price ? (
                      //             <div className="ruppetxt">
                      //               <span>
                      //                 {el?.data?.plan_currency_symbol}
                      //                 {el?.data?.original_price}
                      //               </span>
                      //               {el?.data?.plan_currency_symbol}
                      //               {el?.data?.plan_price}
                      //             </div>
                      //           ) : (
                      //             <div className="ruppetxt">
                      //               {el?.data.plan_currency_symbol}
                      //               {el?.data.plan_price}
                      //             </div>
                      //           )}
                      //         {/* <span class="seeBenefits">See Benefits</span> */}
                      //         <span class="seeBenefits">{textData?.plan_see_benefits_button_text}</span>
                      //       </div>
                      //       {/* <label className="container"> */}
                      //       <input
                      //         type="radio"
                      //         defaultChecked={
                      //           plan_id == el?.data.plan_id
                      //             ? "defaultChecked"
                      //             : el?.data.is_recommended && "defaultChecked"
                      //         }
                      //         value={
                      //           el?.data.plan_name +
                      //           ":" +
                      //           el?.data.plan_id +
                      //           ":" +
                      //           el?.data.plan_price
                      //         }
                      //         name="plan_id"
                      //         onChange={(e) => handleForm(e, el?.data)}
                      //       />
                      //       <span className="checkmark"></span>
                      //     </div>
                      //   </label>
                      //   {el?.data1 && (
                      //     <label
                      //       className={`container ${el?.data1?.isShow === false
                      //         ? "in-active-plan"
                      //         : ""
                      //         }
                      //       `}
                      //       onClick={() => {
                      //         setSelectedPlan(el?.data1)
                      //         setPushbar((prev) => {
                      //           return {
                      //             ...prev,
                      //             seeBenefit: true,
                      //           }
                      //         })
                      //       }}
                      //     >
                      //       <div className={`bxplan`}>
                      //         {el?.data1.is_recommended && (
                      //           <>
                      //             <div
                      //               className="rcomm"
                      //               style={{ display: "none" }}
                      //             >
                      //               {el?.data1?.plan_id === 10
                      //                 ? "Loot Deal"
                      //                 : textData?.pay_recommended_text}
                      //               {/* {textData?.pay_recommended_text} */}
                      //             </div>
                      //             <div>
                      //               <img
                      //                 className="gifimg"
                      //                 alt="pay-gif"
                      //                 // src={
                      //                 //   process.env.REACT_APP_ASSETS_URL +
                      //                 //   "/mobile/images/holi_tag.svg"
                      //                 // }
                      //                 // src={"https://images.hungama.com/c/uploads_hungama/20240910/20240910062219_festive_offer.png"}
                      //                 src="images/mostPopular.png"
                      //               />
                      //             </div>
                      //           </>
                      //         )}
                      //         {/* Free Trial */}
                      //         {el?.data1?.freeTrialPlanRecText && (
                      //           <div className="rcomm">
                      //             {/* {el?.data1?.freeTrialPlanRecText} */}
                      //             {textData?.pay_trialoffer_text}
                      //           </div>
                      //         )}
                      //         {/* {data1.is_recommended && setFormData(data.planid = data1.plan_id)} */}

                      //         <div className="paddpan">
                      //           <p className="typeplan">
                      //             {langConvrt(
                      //               el?.data1.plan_name,
                      //               defaultTextData,
                      //               textData
                      //             )}
                      //           </p>
                      //           {el?.data1.discountedPrice ? (
                      //             <div className="ruppetxt">
                      //               <span>
                      //                 {el?.data1.plan_currency_symbol}
                      //                 {el?.data1.plan_price}
                      //               </span>
                      //               {el?.data1.plan_currency_symbol}
                      //               {el?.data1.discountedPrice}
                      //             </div>
                      //           ) : el?.data1?.original_price >
                      //             el?.data1?.plan_price ? (
                      //             <div className="ruppetxt">
                      //               <span>
                      //                 {el?.data1.plan_currency_symbol}
                      //                 {el?.data1.original_price}
                      //               </span>
                      //               {el?.data1.plan_currency_symbol}
                      //               {el?.data1.plan_price}
                      //             </div>
                      //           ) : (
                      //             <div className="ruppetxt">
                      //               {el?.data1.plan_currency_symbol}
                      //               {el?.data1.plan_price}
                      //             </div>
                      //           )}
                      //           {/* <span class="seeBenefits">See Benefits</span> */}
                      //           <span class="seeBenefits">{textData?.plan_see_benefits_button_text}</span>
                      //         </div>
                      //         {/* <label className="container"> */}
                      //         <input
                      //           type="radio"
                      //           defaultChecked={
                      //             plan_id == el?.data1.plan_id
                      //               ? "defaultChecked"
                      //               : el?.data1.is_recommended &&
                      //               "defaultChecked"
                      //           }
                      //           value={
                      //             el?.data1.plan_name +
                      //             ":" +
                      //             el?.data1.plan_id +
                      //             ":" +
                      //             el?.data1.plan_price
                      //           }
                      //           name="plan_id"
                      //           onChange={(e) => handleForm(e, el?.data1)}
                      //         />
                      //         <span className="checkmark"></span>
                      //       </div>
                      //     </label>
                      //   )}
                      // </SwiperSlide>
//                       <SwiperSlide
//   className={`planSwiperSlider ${bool?.length === 2 ? "plan-four-box" : ""}`}
//   key={index}
// >
//   <label
//     className={`container ${el?.data?.isShow === false ? "in-active-plan" : ""}`}
//   >
//     <div
//       className={`bxplan ${el?.data?.plan_id === 37 ? "adsFree-lg-box" : ""}`}
//     >
//       {el?.data.is_recommended && (
//         <div>
//           <img
//             className="gifimg"
//             alt="pay-gif"
//             src="images/mostPopular.png"
//           />
//         </div>
//       )}
//       <div className="paddpan">
//         <p className="typeplan">
//           {langConvrt(el?.data.plan_name, defaultTextData, textData)}
//         </p>
//         {el?.data.discountedPrice ? (
//           <div className="ruppetxt">
//             <span>
//               {el?.data.plan_currency_symbol}
//               {el?.data.plan_price}
//             </span>
//             {el?.data.plan_currency_symbol}
//             {el?.data.discountedPrice}
//           </div>
//         ) : (
//           <div className="ruppetxt">
//             {el?.data.plan_currency_symbol}
//             {el?.data.plan_price}
//           </div>
//         )}
//         <span
//           className="seeBenefits"
//           onClick={(e) => {
//             e.stopPropagation(); // Prevent label click
//             setSelectedPlan(el?.data);
//             setPushbar((prev) => ({ ...prev, seeBenefit: true }));
//           }}
//         >
//           {textData?.plan_see_benefits_button_text}
//         </span>
//       </div>
//       <input
//         type="radio"
//         defaultChecked={plan_id == el?.data.plan_id}
//         value={`${el?.data.plan_name}:${el?.data.plan_id}:${el?.data.plan_price}`}
//         name="plan_id"
//         onClick={(e) => e.stopPropagation()} // Prevent parent click
//         onChange={(e) => handleForm(e, el?.data)}
//         className="radio-input"
//       />
//       <span className="checkmark"></span>
//     </div>
//   </label>

//   {el?.data1 && (
//     <label
//       className={`container ${el?.data1?.isShow === false ? "in-active-plan" : ""}`}
//     >
//       <div className={`bxplan`}>
//         {el?.data1.is_recommended && (
//           <div>
//             <img
//               className="gifimg"
//               alt="pay-gif"
//               src="images/mostPopular.png"
//             />
//           </div>
//         )}
//         <div className="paddpan">
//           <p className="typeplan">
//             {langConvrt(el?.data1.plan_name, defaultTextData, textData)}
//           </p>
//           {el?.data1.discountedPrice ? (
//             <div className="ruppetxt">
//               <span>
//                 {el?.data1.plan_currency_symbol}
//                 {el?.data1.plan_price}
//               </span>
//               {el?.data1.plan_currency_symbol}
//               {el?.data1.discountedPrice}
//             </div>
//           ) : (
//             <div className="ruppetxt">
//               {el?.data1.plan_currency_symbol}
//               {el?.data1.plan_price}
//             </div>
//           )}
//           <span
//             className="seeBenefits"
//             onClick={(e) => {
//               e.stopPropagation(); // Prevent label click
//               setSelectedPlan(el?.data1);
//               setPushbar((prev) => ({ ...prev, seeBenefit: true }));
//             }}
//           >
//             {textData?.plan_see_benefits_button_text}
//           </span>
//         </div>
//         <input
//           type="radio"
//           defaultChecked={plan_id == el?.data1.plan_id}
//           value={`${el?.data1.plan_name}:${el?.data1.plan_id}:${el?.data1.plan_price}`}
//           name="plan_id"
//           onClick={(e) => e.stopPropagation()} // Prevent parent click
//           onChange={(e) => handleForm(e, el?.data1)}
//           className="radio-input"
//         />
//         <span className="checkmark"></span>
//       </div>
//     </label>
//   )}
// </SwiperSlide>
<SwiperSlide
  className={`planSwiperSlider ${bool?.length === 2 ? "plan-four-box" : ""}`}
  key={index}
>
  <label
    className={`container ${el?.data?.isShow === false ? "in-active-plan" : ""}`}
  >
    <div
      className={`bxplan ${el?.data?.plan_id === 37 ? "adsFree-lg-box" : ""}`}
    >
      {el?.data.is_recommended && (
        <div>
          <img
            className="gifimg"
            alt="pay-gif"
            src="images/mostPopular.png"
          />
        </div>
      )}
      <div className="paddpan">
        <p className="typeplan">
          {langConvrt(el?.data.plan_name, defaultTextData, textData)}
        </p>
        {el?.data.discountedPrice ? (
          <div className="ruppetxt">
            <span>
              {el?.data.plan_currency_symbol}
              {el?.data.plan_price}
            </span>
            {el?.data.plan_currency_symbol}
            {el?.data.discountedPrice}
          </div>
        ) : (
          <div className="ruppetxt">
            {el?.data.plan_currency_symbol}
            {el?.data.plan_price}
          </div>
        )}
        <span
          className="seeBenefits"
          onClick={(e) => {
            e.stopPropagation(); // Prevent label click
            setSelectedPlan(el?.data);
            setPushbar((prev) => ({ ...prev, seeBenefit: true }));
          }}
        >
          {textData?.plan_see_benefits_button_text}
        </span>
      </div>
      <input
        type="radio"
        defaultChecked={plan_id == el?.data.plan_id}
        value={`${el?.data.plan_name}:${el?.data.plan_id}:${el?.data.plan_price}`}
        name="plan_id"
        onClick={(e) => {
          e.stopPropagation(); // Prevent parent click
          setSelectedPlan(el?.data); // Update the selected plan
        }}
        onChange={(e) => handleForm(e, el?.data)}
        className="radio-input"
      />
      <span className="checkmark"></span>
    </div>
  </label>

  {el?.data1 && (
    <label
      className={`container ${el?.data1?.isShow === false ? "in-active-plan" : ""}`}
    >
      <div className={`bxplan`}>
        {el?.data1.is_recommended && (
          <div>
            <img
              className="gifimg"
              alt="pay-gif"
              src="images/mostPopular.png"
            />
          </div>
        )}
        <div className="paddpan">
          <p className="typeplan">
            {langConvrt(el?.data1.plan_name, defaultTextData, textData)}
          </p>
          {el?.data1.discountedPrice ? (
            <div className="ruppetxt">
              <span>
                {el?.data1.plan_currency_symbol}
                {el?.data1.plan_price}
              </span>
              {el?.data1.plan_currency_symbol}
              {el?.data1.discountedPrice}
            </div>
          ) : (
            <div className="ruppetxt">
              {el?.data1.plan_currency_symbol}
              {el?.data1.plan_price}
            </div>
          )}
          <span
            className="seeBenefits"
            onClick={(e) => {
              e.stopPropagation(); // Prevent label click
              setSelectedPlan(el?.data1);
              setPushbar((prev) => ({ ...prev, seeBenefit: true }));
            }}
          >
            {textData?.plan_see_benefits_button_text}
          </span>
        </div>
        <input
          type="radio"
          defaultChecked={plan_id == el?.data1.plan_id}
          value={`${el?.data1.plan_name}:${el?.data1.plan_id}:${el?.data1.plan_price}`}
          name="plan_id"
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent click
            setSelectedPlan(el?.data1); // Update the selected plan
          }}
          onChange={(e) => handleForm(e, el?.data1)}
          className="radio-input"
        />
        <span className="checkmark"></span>
      </div>
    </label>
  )}
</SwiperSlide>

                    );
                  })}
                </Swiper>
              </div>

              {/* {couponApplied ? (
                <div className="ccod">
                  <div
                    onClick={(e) => handleCoupenClick(e)}
                    className="cupcode pointer"
                  >
                    {textData?.secondLang?.pay_coupon_placeholder_text
                      ? textData?.secondLang?.pay_coupon_placeholder_text
                      : textData?.default?.pay_coupon_placeholder_text}
                  </div>
                </div>
              ) : (
                <div className="ccapplyout">
                  <span>
                    {formData.couponcode}{" "}
                    {textData?.secondLang?.pay_coupon_applied_text
                      ? textData?.secondLang?.pay_coupon_applied_text
                      : textData?.default?.pay_coupon_applied_text}
                    !
                  </span>
                  <div
                    className="crossicn"
                    onClick={(e) => handleCouponCancel()}
                  ></div>
                </div>
              )} */}
              {/* <div class="cpbxcon">
                           <div class="cpbxcon-input-box">
                              <input autocomplete="off" type="text" name="couponcode" id="couponcode" value=""/>
                              <a href="!#" class="button-blue">Apply</a>
                           </div>
                             
                  </div> */}
              {/* show coupon box only for india, due to google policy: 14-June-2023 */}
              {/* {country?.toLowerCase() === "in" && (
                <div class="cpbxcon">
                  <div class="cpbxcon-input-box">
                    <input
                      autoComplete="off"
                      class={
                        formData.couponError === true
                          ? "error-border"
                          : (formData?.couponError === false &&
                            formData?.couponStatus === 1) ||
                            (formData?.couponError === false &&
                              formData?.couponStatus === 2)
                            ? "success-border"
                            : ""
                      }
                      type="text"
                      name="couponcode"
                      id="couponcode"
                      placeholder={textData?.pay_coupon_placeholder}
                      value={formData.couponcode}
                      onChange={(e) => handleForm(e)}
                    />

                    <a
                      href="!#"
                      style={{
                        backgroundColor: applyBtn === true ? "white" : "",
                        pointerEvents: applyBtn === true ? "auto" : "none",
                        display: showApplyBtn === false ? "none" : "",
                      }}
                      class="button-blue"
                      onClick={(e) => verifyCoupon(e)}
                    >
                      {textData?.pay_coupon_apply_text}
                    </a>

                    {!showApplyBtn && (
                      <img
                        alt="close icon"
                        onClick={(e) => handleClearCoupon(e, identity)}
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/coupon-error-close-new.svg"
                        }
                        class="error-closebtn"
                      />
                    )}
                  </div>
                  {formData?.couponError && formData?.couponStatus === 0 && (
                    <div class="error-box">
                      {textData?.pay_coupon_invalid_text}
                    </div>
                  )}
                  {formData?.couponError && formData?.couponStatus === -1 && (
                    <div class="error-box">
                      {textData?.pay_coupon_expired_text}
                    </div>
                  )}
                  {!formData?.couponError && formData?.couponStatus === 1 && (
                    <div class="success-box">
                      {textData?.pay_coupon_valid_text}
                    </div>
                  )}
                  {!formData?.couponError && formData?.couponStatus === 2 && (
                    <div class="success-box">
                      {textData?.pay_coupon_valid_text}
                    </div>
                  )}
                </div>
              )} */}

              {/* <div className="CTR gemg">{textData?.pay_features_heading}</div>
              <div className="feature-bar">
                <div className="featurepadd">
                  <div className="featurehead">
                    <div className="fratcol">{textData?.pay_features}</div> */}
              {/* <div className="fratcol1">
                      <span className="adfree">
                        {textData?.pay_features_type_1}
                      </span>
                    </div> */}
              {/* <div className="fratcol1">
                      <span className="adfree">
                        {textData?.pay_features_type_2}
                      </span>
                    </div> */}
              {/* <div className="fratcol1">
                      <span className="adfree">
                        {textData?.pay_features_type_3}
                      </span>
                    </div>
                  </div>
                  <div className="featurerow brdn">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_1}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                  <div className="featurerow brdn">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_2}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                  <div className="featurerow brdn">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_3}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                  <div className="featurerow brdn">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_4}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                  <div className="featurerow brdn">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_5}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                  <div className="featurerow brdn">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_6}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                  <div className="featurerow">
                    <div className="fratcolrow">
                      {textData?.pay_features_list_7}
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/crpssimg.svg"
                        }
                        alt="crpssimg"
                      />
                    </div> */}
              {/* <div className="fratcol1">
                      <img
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/tickimg.svg"
                        }
                        alt="tickimg"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="trygold">
                <div className="CTR gemg">
                  {textData?.pay_pickplan_title_text}
                </div>
                <div className="takeexp">
                  {textData?.pay_pickplan_subtitle_text}
                </div>
                <div
                  className="CTR"
                  id="scrolltop"
                  onClick={() => { */}
                    //G4 Test Event
              {/* ReactGA4.event({
                      category: "GA4 Test Event",
                      action: "pick a plan button clicked",
                      label: "Pick a Plan",
                    });
                  }}
                >
                  <a
                    href="#planstart"
                    className="blueprimary_btn mb30"
                    onClick={() => { */}
                      //G4 Test Event
              {/* ReactGA4.event({
                        category: "GA4 Test Event",
                        action: "pick a plan button clicked",
                        label: "Pick a Plan",
                      });
                    }}
                  >
                    {textData?.pay_pickplan_btn_text}
                  </a>
                </div>
              </div> */}
              <div className="goldbgmain">
                <input type="hidden" id="x_plan_name" />
                <input type="hidden" id="x_plan_price" />
                <div
                  className={planDetailsId === "960" ? "goldbtn pointer disablegoldbtn" : "goldbtn pointer"}
                  onClick={() => planDetailsId === "960" ? null : selectedPlan && planDetailsId !== "960" ? setPushbar((prev) => { return { ...prev, seeSubcribe: true } }) : null}
                >
                  <img
                    src={
                      process.env.REACT_APP_ASSETS_URL +
                      "/mobile/images/goldimg.svg"
                    }
                    alt="gold"
                  />{" "}
                  {/* Subscribe */}
                  {textData?.pay_subscribe_button_txt}
                </div>
                {/* <div class="CTR"><a href="!#" class="alsub">Already have Subscription</a></div> */}
                <div class="CTR">
                  {/* <p class="alsub" style={{ borderBottom: "0px  solid" }}>
                    <span style={{ color: 'white', fontSize: '12px' }}>Premium subscription auto-renewal . Cancel Anytime</span>
                  </p> */}
                  <p class="alsub" style={{ borderBottom: "0px  solid" }}>
                    <span style={{ color: 'white', fontSize: '12px' }}>{textData?.plan_disclaimer_text}</span>
                  </p>
                  <p class="alsub" style={{ borderBottom: "0px  solid" }}>
                    {/* <span style={{ color: '#ffad12', fontSize: '12px' }}>Terms & Conditions</span> */}
                    <span style={{ color: '#ffad12', fontSize: '12px' }} onClick={() =>redirectTC()}>{textData?.terms_and_condition}</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="mainwrp">
              <div className="header">
                {/* <div className="arwback" onClick={goToPreviousPath}>
                  <span className="icon-Back" onClick={goToPreviousPath}></span>
                </div> */}
                <a href="https://tossana.kollywoodhungama.com/back" >
                  <div className="arwback">
                    <span className="icon-Back"></span>
                  </div>
                </a>
                <div className="hlogo"></div>
              </div>
            </div>

            {/* <aside id="couponsection" ref={ref} className={openCoupon ? "pushbar from_bottom opened" : "pushbar from_bottom"}>
    <div className="paddcpbox">
        <div className="cptitle">{textData?.secondLang?.pay_coupon_popup_text ? textData?.secondLang?.pay_coupon_popup_text : textData?.default?.pay_coupon_popup_text}</div>
        <div className="cpbxcon">
            <input type="text" value={formData.couponcode} name="couponcode" onChange={e => handleForm(e)} />
            <div onClick={e => verifyCoupon(e)} className="apply_btn cpbtn pointer">
                {textData?.secondLang?.pay_coupon_popup_apply_text ? textData?.secondLang?.pay_coupon_popup_apply_text : textData?.default?.pay_coupon_popup_apply_text}
            </div>
        </div>
    </div>
</aside> */}
          </div>
          {/* <aside
            data-pushbar-id="bottom"
            className={
              openCoupon ? "pushbar from_bottom opened" : "pushbar from_bottom"
            }
          >
            <div class="paddcpbox">
              <div
                class="coupon-close"
                onClick={(e) => setOpenCoupon(false)}
                data-pushbar-close
              >
                <img
                  alt="close"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/images/close-btn.svg"
                  }
                />
              </div>
              <div class="close_sep"></div>
              <div className="cptitle">
                {textData?.secondLang?.pay_coupon_popup_text
                  ? textData?.secondLang?.pay_coupon_popup_text
                  : textData?.default?.pay_coupon_popup_text}
              </div>
              <div class="cpbxcon">
                <div class="cpbxcon-input-box">
                  <input
                    autoComplete="off"
                    class={formData.couponError && "error-border"}
                    type="text"
                    name="couponcode"
                    id="couponcode"
                    value={formData.couponcode}
                    onChange={(e) => handleForm(e)}
                  />
                  {formData.couponError && (
                    <img
                      alt="close icon"
                      onClick={(e) => handleClearCoupon(e)}
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/images/error-close.svg"
                      }
                      class="error-closebtn"
                    />
                  )}
                </div>
                <a
                  href="!#"
                  class="button-blue"
                  onClick={(e) => verifyCoupon(e)}
                >
                  {textData?.secondLang?.pay_coupon_apply_text
                    ? textData?.secondLang?.pay_coupon_apply_text
                    : textData?.default?.pay_coupon_apply_text}
                </a>
              </div>
              {formData.couponError && (
                <div class="error-box">Invalid Coupon Code</div>
              )}
              

            </div>
          </aside> */}
          {/* See Benefits Pop Up */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom see-benefits-pop-up ${pushbar?.seeBenefit ? "opened" : ""
              }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="paypop-content">
                <div className="logo-image">
                  <img src="images/logo.png" alt="Logo Image" />
                </div>
                <div className="pop-pay-icon-box pop-heading">
                  {/* <p className="pop-up-title">FREE Watching with Freemium! Upgrade to
                    Premium for all features.</p> */}
                  <p className="pop-secure price-plan">{selectedPlan?.plan_currency_symbol}{selectedPlan?.plan_price}</p>
                  <p className="pop-secure day-plan"> {langConvrt(selectedPlan?.plan_name, defaultTextData, textData)} {textData?.plan_benefits_header}</p>
                </div>
                <div className="pop-plan-benefits">
                  <img src="images/ads.png" alt="Ads Image" />
                  <img src="images/streaming.png" alt="Streaming Image" />
                  {/* <img src="images/data3gb.png" alt="Data 3GB Image" /> */}
                  <img
                            src={`images/data${selectedPlan?.plan_name === 'DAILY'
                                ? 'mpremium'
                                : selectedPlan?.plan_name === 'WEEKLY'
                                    ? '3gb'
                                    : selectedPlan?.plan_name === 'MONTHLY MS'
                                        ? '5gb'
                                        : selectedPlan?.plan_name === 'MONTHLY ML'
                                            ? '10gb'
                                            : ''
                                }.png`}
                            alt="Data Image"
                        />
                  <img src="images/access.png" alt="Access Image" />
                </div>
                <a
                  class="button-blue"
                  onClick={() => setPushbar((prev) => { return { ...prev, seeBenefit: false, seeSubcribe: true } })}
                >
                  <Image src="goldimg.svg" alt="golimg" />
                  {/* Subscribe */}
                  {textData?.pay_subscribe_button_txt}
                </a>
                {/* <p className="pop-cancel" onClick={() => setPushbar((prev) => { return { ...prev, seeBenefit: false } })}>Cancel</p> */}
                <p className="pop-cancel" onClick={() => setPushbar((prev) => { return { ...prev, seeBenefit: false } })}>{textData?.pay_popup_cancel}</p>
              </div>
            </div>
          </aside>
          {/* See Subscribe Pop Up */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom see-subscribe-pop-up ${pushbar?.seeSubcribe ? "opened" : ""
              }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="paypop-content">
                <div className="logo-image">
                  <img src="images/logo.png" alt="Logo Image" />
                </div>
                <div className="pop-pay-icon-box pop-heading">
                  <p className="pop-up-title">{textData?.pay_popup_confimation_txt1}{langConvrt(selectedPlan?.plan_name, defaultTextData, textData)} {textData?.pay_popup_confimation_txt2} {selectedPlan?.plan_currency_symbol}{selectedPlan?.plan_price}/{langConvrt(selectedPlan?.duration?.text, defaultTextData, textData)} {textData?.pay_popup_confimation_txt3}</p>
                </div>
                {error && <p className="error-text">{error}</p>}
                <a
                  class="button-blue"
                  onClick={() => handleConfirm()}
                >
                  <Image src="goldimg.svg" alt="golimg" />
                  {/* Confirm */}
                  {textData?.pay_confirm_button}
                </a>
                {/* <p className="pop-cancel" onClick={() => setPushbar((prev) => { return { ...prev, seeSubcribe: false } })}>Cancel</p> */}
                <p className="pop-cancel" onClick={() => setPushbar((prev) => { return { ...prev, seeSubcribe: false } })}>{textData?.pay_popup_cancel}</p>
              </div>
            </div>
          </aside>
          {/* See Otp Pop Up */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom see-otp-pop-up ${pushbar?.seeOtp ? "opened" : ""
              }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="paypop-content">
                <div className="logo-image">
                  <img src="images/logo.png" alt="Logo Image" />
                </div>
                <div className="pop-pay-icon-box pop-heading">
                  <p className="pop-up-title">{textData?.pay_popup_confirm_otp_header1} +855 {subcribeResponse?.msisdn}</p>
                </div>
                <div className="otp-input-container">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={handlePaste}
                      className={`otp-input-box ${error && "otp-error-box"}`}
                    />
                  ))}
                </div>
                {error && <p className="error-text">{error}</p>}
                <a
                  class="button-blue"
                  onClick={() => handleVerifyOtp()}
                >
                  <Image src="goldimg.svg" alt="golimg" />
                  {/* Verify */}
                  {textData?.pay_popup_verify_button}
                </a>
                {/* <p className="pop-cancel" onClick={() => setPushbar((prev) => { return { ...prev, seeOtp: false } })}>Cancel</p> */}
                <p className="pop-cancel" onClick={() => setPushbar((prev) => { return { ...prev, seeOtp: false } })}>{textData?.pay_popup_cancel}</p>
                <div className="resend-otp-container">
                  {/* Didn't receive OTP ? {resendAvailable ? ( */}
                  {textData?.pay_popup_confirm_otp_txt1} {resendAvailable ? (
                    <span className="resend-otp" onClick={() => handleResendOtp()}>
                      {/* Resend OTP */}
                      {textData?.pay_popup_resend_otp_button}
                    </span>
                  ) : (
                    <span className="resend-otp">
                      {/* Resend OTP in {formatTime(timer)} */}
                      {textData?.pay_popup_resend_otp_button} in {formatTime(timer)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </aside>
          {/* See Congratulation Pop Up */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom see-congratulation-pop-up ${pushbar?.seeCongratulation ? "opened" : ""
              }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="paypop-content">
                <div className="logo-image">
                  <img src="images/logo.png" alt="Logo Image" />
                </div>
                <div className="right-image">
                  <img src="images/right.png" alt="Right Image" />
                </div>
                <div className="subcribe-text">
                  {/* <p className="textTitle">Congratulations!</p>
                  <p>Your are now a Premium User.</p>
                  <p>Enjoy ad free movies, TV Shows, Music Videos.</p> */}
                  <p className="textTitle">{textData?.pay_popup_payment_success_header1}</p>
                  <p>{upgrade == true ? (textData?.payment_str_2) : textData?.pay_popup_payment_success_header2}</p>
                  <p v-if={upgrade == false}>{textData?.pay_popup_payment_success_header3}</p>
                </div>
                <a
                  href="https://tossana.kollywoodhungama.com/payment/success"
                  class="button-blue"
                >
                  {/* Start Exploring */}
                  {textData?.pay_popup_payment_success_button_text}
                </a>
              </div>
            </div>
          </aside>
          {/* See Payment Failed Pop Up */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom see-payment-failed-pop-up ${pushbar?.seePaymentFailed ? "opened" : ""
              }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="paypop-content">
                <div className="logo-image">
                  <img src="images/logo.png" alt="Logo Image" />
                </div>
                <div className="wrong-image">
                  <img src="images/wrong.png" alt="Wrong Image" />
                </div>
                <div className="subcribe-text">
                  {/* <p className="textTitle">Payment Failed</p>
                  <p>Oops !! your payment failed</p>
                  <p>Enjoy ad free movies, TV Shows, Music Videos.</p> */}
                  <p className="textTitle">{textData?.pay_popup_payment_fail_header1}</p>
                  <p>{textData?.pay_popup_payment_fail_header2}</p>
                  <p>{textData?.pay_popup_payment_fail_header3}</p>
                </div>
                <a
                  href="https://tossana.kollywoodhungama.com/payment/failed"
                  class="button-blue"
                >
                  {/* Retry */}
                  {textData?.pay_popup_payment_fail_button_text}
                </a>
              </div>
            </div>
          </aside>
        </>
      )}
    </>
  );
}
